<template>
  <div class="squad-wrapper pa-1">
    <div class="d-flex justify-end">
      <v-btn @click="$emit('delete')" text x-small color="error" :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }">{{ translations.removeSquad }}</v-btn>
    </div>
    <v-card height="400px">
      <div :style="[{ backgroundColor: this.$vuetify.theme.themes.light.main }]">
        <!-- If squad has name -->
        <div class=" d-flex align-center justify-space-between px-2 fill-height" style="height: 40px">
          <div class="reduce-padding">
            <span v-if="!editingName" class="white--text">
              {{ squadName }}
            </span>

            <v-text-field v-else v-model="squadName" solo outlined hide-details dense height="35px"></v-text-field>
          </div>

          <div class="d-flex align-center">
            <v-btn v-if="!editingName" @click="editingName = true" x-small icon :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }">
              <v-icon small color="white">mdi-pencil</v-icon>
            </v-btn>

            <v-btn v-if="editingName" @click="backHandler" x-small icon class="mr-2">
              <v-icon small>mdi-close</v-icon>
            </v-btn>

            <v-btn v-if="editingName" @click="$emit('update', squadName)" x-small icon>
              <v-icon small color="white">mdi-check</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- List of employees -->
      <div @dragover="dragOver" @drop="$emit('drop-on-squad')" class="scroll-bar pa-2" style="max-height: 360px; min-height: 360px;">
        <template v-if="squad.employees.length > 0">
          <div v-for="employee in squad.employees" :key="employee.id" class="d-flex align-center justify-space-between">
            <div>
              <span>{{ employee.name }}</span>
            </div>
            <div>
              <v-btn @click="$emit('remove-employee', employee.id)" small icon :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </template>

        <template v-else>
          <div style="text-align: center">
            <div class="d-flex justify-center mt-4" style="width: 100%">
              <v-img max-width="250px" :src="require('../../assets/noData.svg')" style="cursor: pointer"></v-img>
            </div>
            <p>{{ translations.noAssignedEmployeesToSquad }}</p>
          </div>
        </template>
      </div>
    </v-card>
  </div>
</template>


<script>
import { translations } from '@/utils/common'

export default {
  name: 'SquadCard',

  props: {
    squad: {
      type: Object,
      required: true,
      default: () => {
      }
    },

    canReadOnly: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      translations: translations,

      squadName: '',
      editingName: false
    }
  },

  created() {
    this.squadName = this.squad.name
  },

  methods: {
    dragOver(e) {
      e.preventDefault()
    },

    backHandler() {
      this.squadName = this.squad.name
      this.editingName = false
    }
  },

  watch: {
    squad: {
      handler: function(newVal) {
        if (newVal) this.editingName = false
      },
      deep: true
    }
  }
}
</script>


<style>
.squad-wrapper {
  width: 360px;
  max-width: 360px;
}

.reduce-padding > .v-text-field > .v-input__control > .v-input__slot {
  max-height: 26px !important;
  min-height: 26px !important;
}

.reduce-padding > .v-text-field > .v-input__control {
  max-height: 26px !important;
  min-height: 26px !important;
}
</style>
