<template>
  <v-row class="ma-0" justify="center">
    <v-dialog v-model="dialog"
              @click:outside="$emit('closeDialog')"
              @keydown="value => value.key === 'Escape' ? $emit('closeDialog') : ''"
              scrollable max-width="700px">

      <v-card class="pa-1">
        <!-- card title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations[isUpdate ? 'updateAccountEmployee' : 'newAccountEmployee'] }}</h3>
        </v-card-title>

        <v-card-text>
          <v-tabs>
            <v-tab key="account">{{ translations.manageAccounts }}</v-tab>
            <v-tab key="employee">{{ translations.employee }}</v-tab>
            <v-tab key="capabilities">{{ translations.backOffice }}</v-tab>
            <v-tab v-if="disableAccountProjects" key="projects">{{ translations.projects }}</v-tab>

            <!-- tab project -->
            <v-tab-item key="project" class="tab-item-style">
              <v-card-text>
                <!-- section account -->
                <div class="section-divider mt-1">
                  <div class="section-divider-title">{{ translations.details }}</div>

                  <!-- name -->
                  <v-col class="pa-0 mt-4">
                    <b class="ml-8">{{ translations.username }} *</b>
                    <v-text-field v-model="selectedItem.name" prepend-icon="mdi-text"
                                  :readonly="isAccountReadOnly" counter="50"
                                  :rules="[requiredRule]"
                                  class="mt-1" outlined dense>
                    </v-text-field>
                  </v-col>

                  <!-- email -->
                  <v-col class="pa-0 mt-1">
                    <b class="ml-8">{{ translations.email }} *</b>
                    <smart-text-field v-model="selectedItem.email" prepend-icon="mdi-email-outline"
                                      :readonly="isAccountReadOnly"
                                      :rules="[requiredRule, emailRule]"
                                      :already-exists-function="checkEmail"
                                      @has-error="value => hasError.email = value">
                    </smart-text-field>
                  </v-col>
                </div>

                <!-- section password -->
                <div class="section-divider mt-12">
                  <div class="section-divider-title">{{ translations.password }}</div>

                  <!-- set password -->
                  <div v-if="selectedItem.isActive">
                    <!-- password -->
                    <v-col class="pa-0 mt-2">
                      <b class="ml-8">{{ translations[isUpdate ? 'resetPassword' : 'addNewPassword'] }} {{ isUpdate ? '' : ' *' }}</b>
                      <div class="d-flex">
                        <v-text-field v-model="selectedItem.newPassword" prepend-icon="mdi-key" :placeholder="translations.addNewPassword" type="password"
                                      :readonly="isAccountReadOnly"
                                      :rules="[isUpdate ? true : requiredRule]"
                                      @keydown.space.prevent
                                      outlined dense />
                      </div>
                    </v-col>

                    <v-col class="pa-0 mt-2">
                      <b class="ml-8">{{ translations.changePasswordAtNextLogon }}</b>
                      <v-switch v-model="selectedItem.changePasswordAtNextLogon" prepend-icon="mdi-autorenew"
                                :readonly="isAccountReadOnly"
                                class="mt-1" dense />
                    </v-col>
                  </div>
                </div>
              </v-card-text>
            </v-tab-item>


            <!-- tab employee -->
            <v-tab-item key="employee" class="tab-item-style">
              <v-card-text>
                <!-- section employee -->
                <div v-if="currentUserCanManageCRUD || selectedItem.employeeId" class="section-divider mt-1">
                  <div class="section-divider-title">{{ translations.employee }}</div>

                  <!-- switch to edit the employee -->
                  <v-col v-if="!selectedItem.employeeId" class="pa-0 mt-2">
                    <b class="ml-8">{{ translations.showEmployeeData }}</b>
                    <v-switch v-model="isEditingEmployee" prepend-icon="mdi-account-details"
                              class="mt-1" dense />
                  </v-col>

                  <!-- employee data -->
                  <div v-if="isEditingEmployee">
                    <!-- employmentStartDate -->
                    <v-col class="pa-0 mt-4">
                      <b class="ml-8">{{ translations.employmentStartDate }} *</b>
                      <date-picker :dateProp="employmentStartDate"
                                   @selected-date="value => setDate(value, 'employmentStartDate')"
                                   :disable-picker="isEmployeeReadOnly"
                                   :classToAdd="['date-picker-component']">
                      </date-picker>
                    </v-col>

                    <!-- employmentEndDate -->
                    <v-col class="pa-0 mt-6">
                      <b class="ml-8">{{ translations.employmentEndDate }}</b>
                      <date-picker :dateProp="employmentEndDate"
                                   :startToDate="startToDate"
                                   :minDateProp="employmentStartDate.simple"
                                   @selected-date="value => setDate(value, 'employmentEndDate')"
                                   :disable-picker="isEmployeeReadOnly"
                                   :clearable="!isEmployeeReadOnly"
                                   :classToAdd="['date-picker-component']">
                      </date-picker>
                    </v-col>

                    <!-- keycode -->
                    <v-col class="pa-0 mt-6">
                      <b class="ml-8">{{ translations.code }} *</b>
                      <smart-text-field v-model="selectedItem.keyCode" prepend-icon="mdi-roman-numeral-3"
                                        :readonly="isEmployeeReadOnly" counter="20"
                                        :rules="[requiredRule]"
                                        :already-exists-function="checkKeyCode"
                                        @has-error="value => hasError.keyCode = value">
                      </smart-text-field>
                    </v-col>

                    <!-- family -->
                    <v-col class="pa-0 mt-1">
                      <b class="ml-8">{{ translations.family }}</b>
                      <v-text-field v-model="selectedItem.family" prepend-icon="mdi-tune"
                                    :readonly="isEmployeeReadOnly" counter="60"
                                    :clearable="!isEmployeeReadOnly"
                                    class="mt-1" outlined dense>
                      </v-text-field>
                    </v-col>

                    <!-- qualification -->
                    <v-col class="pa-0 mt-1">
                      <b class="ml-8">{{ translations.qualification }} *</b>
                      <v-text-field v-model="selectedItem.qualification" prepend-icon="mdi-tune-variant"
                                    :readonly="isEmployeeReadOnly" :rules="[requiredRule]" counter="20"
                                    class="mt-1" outlined dense>
                      </v-text-field>
                    </v-col>

                    <!-- type -->
                    <v-col class="pa-0 mt-1">
                      <b class="ml-8">{{ translations.employeeType }}</b>
                      <v-text-field v-model="selectedItem.type" prepend-icon="mdi-tune"
                                    :readonly="isEmployeeReadOnly" counter="60"
                                    :clearable="!isEmployeeReadOnly"
                                    class="mt-1" outlined dense>
                      </v-text-field>
                    </v-col>

                    <!-- taxCode -->
                    <v-col class="pa-0 mt-1">
                      <b class="ml-8">{{ translations.taxCode }}</b>
                      <v-text-field v-model="selectedItem.taxCode" prepend-icon="mdi-code-brackets"
                                    :readonly="isEmployeeReadOnly" :rules="[taxCodeRule]" counter="16"
                                    :clearable="!isEmployeeReadOnly"
                                    class="mt-1" outlined dense>
                      </v-text-field>
                    </v-col>

                    <!-- phoneNumber -->
                    <v-col class="pa-0 mt-1">
                      <b class="ml-8">{{ translations.phoneNumber }}</b>
                      <v-text-field v-model="selectedItem.phoneNumber" prepend-icon="mdi-phone"
                                    :readonly="isEmployeeReadOnly"
                                    :clearable="!isEmployeeReadOnly"
                                    class="mt-1" outlined dense>
                      </v-text-field>
                    </v-col>
                  </div>

                  <!-- button to delete the employee -->
                  <v-col v-if="currentUserCanManageCRUD && selectedItem.employeeId" class="pt-0 my-2">
                    <v-btn @click="disableEmployeeConfirmation"
                           small outlined color="error" class="white--text ml-6">
                      {{ translations.remove }} {{ translations.employee }}
                    </v-btn>
                  </v-col>
                </div>
              </v-card-text>
            </v-tab-item>


            <!-- tab capabilities -->
            <v-tab-item key="capabilities" class="tab-item-style">
              <v-card-text>
                <!-- section capabilities -->
                <div class="section-divider mt-1">
                  <div class="section-divider-title">{{ translations.capabilities }}</div>

                  <!-- capabilities -->
                  <v-col class="pa-0">
                    <v-list-item-group>
                      <v-list-item v-for="capability in filteredCapabilities" :key="capability.name">
                        <v-list-item-action class="mr-3">
                          <v-checkbox :input-value="checkedStates[capability.name]" :value="isChecked(capability)"
                                      :disabled="!currentUserCanManageAccount || capability.disabled"
                                      @change="updateCheckedCapabilities($event, capability)">
                          </v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ translations['capabilityTitle ' + capability.name] }}
                            <v-chip v-if="capability.disabled" small color="red" class="white--text ml-1 mb-1">{{ translations.featureNotAvailable }}</v-chip>
                            <v-chip v-if="capability.name === 'Admin'" small color="main" class="white--text ml-1 mb-1">{{ translations.admin }}</v-chip>
                          </v-list-item-title>
                          <v-list-item-subtitle>{{ translations['capabilityDescr ' + capability.name] }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-col>
                </div>
              </v-card-text>
            </v-tab-item>


            <!-- tab projects -->
            <v-tab-item v-if="disableAccountProjects" key="projects" class="tab-item-style">
              <v-card-text>
                <!-- section projects account-project -->
                <account-project-section :account-id="selectedItem.accountId"></account-project-section>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <!-- card actions -->
        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="space-around">
            <v-btn @click="$emit('closeDialog')" class="px-8" outlined :loading="loading">{{ translations.cancel }}</v-btn>

            <v-btn v-if="currentUserCanManageCRUD || currentUserCanManageAccount"
                   @click="confirm" :disabled="disableBtn" :loading="loading"
                   color="main" class="white--text px-8">
              {{ translations.confirm }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.saveError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'
import { Rules } from '@/utils/mixins/Rules'

import AccountService from '@/service/account/AccountService'
import EmployeeService from '@/service/employee/EmployeeService'
import CapabilityService from '@/service/capability/CapabilityService'

import AccountProjectSection from '@/views/Account/AccountProjectSection'
import SmartTextField from '@/components/SmartTextField'
import DatePicker from '@/components/DatePicker'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'AccountSaveEditDialog',

  components: {
    AccountProjectSection,
    SmartTextField,
    DatePicker,
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      default: () => {
      }
    },

    show: {
      type: Boolean,
      default: false,
      required: true
    },

    update: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      dialog: false,
      loading: false,
      isUpdate: false,
      showErrorMsgDialog: false,
      isEditingEmployee: false,
      hasError: {
        email: false,
        keyCode: false
      },

      selectedItem: {},
      startToDate: '',
      employmentStartDate: null,
      employmentEndDate: null,

      checkedCapabilities: [],
      checkedStates: {}
    }
  },

  created() {
    this.setItem()
  },

  computed: {
    ...mapState({
      currentUserIsAdmin: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Admin'),
      currentUserCanManageCRUD: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Manage CRUD'),
      currentUserCanManageAccount: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Manage account')
    }),

    filteredCapabilities() {
      return this.$store.getters.capabilities.filter(capability => this.currentUserIsAdmin || capability.name !== 'Admin')
    },

    isChecked() {
      return capability => this.checkedCapabilities.some(item => item.name === capability.name)
    },

    disableBtn() {
      const emailValid = !this.hasError.email && this.selectedItem.email && this.emailRegex().test(this.selectedItem.email)
      const nameValid = this.validRequired(this.selectedItem.name, 50)
      const passwordValid = this.isUpdate || this.selectedItem.newPassword

      // if editing employee is active
      if (this.isEditingEmployee) {
        return !(
          emailValid && nameValid && passwordValid
          && (!!this.employmentStartDate.simple)
          && (!this.hasError.keyCode && this.validRequired(this.selectedItem.keyCode, 20))
          && (this.validRequired(this.selectedItem.qualification, 20))
          && (!this.selectedItem.taxCode || this.taxCodeRegex().test(this.selectedItem.taxCode))
          && (!this.selectedItem.type || this.selectedItem.type.length <= 60)
        )
      }

      // if not editing employee, or no employee fields are filled
      return !(
        emailValid && nameValid && passwordValid
        && this.checkedCapabilities.length > 0
      )
    },

    isEmployeeReadOnly() {
      return !this.currentUserCanManageCRUD
    },

    isAccountReadOnly() {
      return !this.currentUserCanManageAccount
    },

    disableAccountProjects() {
      return this.selectedItem.accountId
        && this.checkedCapabilities.some(c => c.name === 'Back Office' || c.name === 'Activity Planner')
        && !this.checkedCapabilities.some(c => c.name === 'All Projects')
    }
  },

  mixins: [
    MomentMixin,
    Rules
  ],

  methods: {
    async setItem() {
      if (this.item.accountId) {
        this.selectedItem = { ...this.item }
        await this.retrieveEmployeeInfo()
        delete this.selectedItem['__typename']
        await this.updateCheckedStates()
        this.isEditingEmployee = this.selectedItem.employeeId
        this.prepDates()
      } else {
        this.selectedItem = {
          isActive: true
        }
        this.checkedCapabilities = []
        this.checkedStates = {}
        this.isEditingEmployee = false
        this.getEmptyDates()
      }
    },

    async retrieveEmployeeInfo() {
      if (this.selectedItem.employeeId) {
        await AccountService.retrieveEmployeeInfo({
          employeeId: this.selectedItem.employeeId
        }).then(employeeRetrieved => {
          this.selectedItem = { ...this.selectedItem, ...employeeRetrieved }
        })
      }
    },

    async updateCheckedStates() {
      this.checkedCapabilities = await CapabilityService.capabilitiesByAccountId({ accountId: this.selectedItem.accountId })
      this.$store.getters.capabilities.forEach(capability => {
        this.checkedStates[capability.name] = this.checkedCapabilities.some(checkedCapability => checkedCapability.name === capability.name)
      })
    },

    prepDates() {
      const { employmentStartDate, employmentEndDate } = this.selectedItem
      // simple
      this.employmentStartDate.simple = employmentStartDate
      this.employmentEndDate.simple = employmentEndDate
      // holder text field
      this.employmentStartDate.holder = employmentStartDate ? this.momentDate(employmentStartDate, 'DD/MM/YYYY') : ''
      this.employmentEndDate.holder = employmentEndDate ? this.momentDate(employmentEndDate, 'DD/MM/YYYY') : ''
      // picker
      this.employmentStartDate.picker = employmentStartDate ? this.formatDate(employmentStartDate) : ''
      this.employmentEndDate.picker = employmentEndDate ? this.formatDate(employmentEndDate) : ''
      // startToDate
      this.startToDate = employmentStartDate ? this.employmentStartDate.picker : ''
    },

    getEmptyDates() {
      this.startToDate = ''
      this.employmentStartDate = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.employmentEndDate = {
        simple: '',
        holder: '',
        picker: ''
      }
    },

    setDate(value, type) {
      this[type].simple = value.simple
      this[type].holder = this.momentDate(value.simple, 'DD/MM/YYYY')
      this[type].picker = this.formatDate(value.simple)

      if (type === 'employmentStartDate') {
        this.startToDate = value.picker
      }
    },

    checkEmail(email) {
      return AccountService.checkAccountEmail({ email: email })
    },

    checkKeyCode(keyCode) {
      return AccountService.checkKeyCodeEmployee({ keyCode: keyCode })
    },

    async confirm() {
      this.loading = true

      // prepare the object
      let accountDTO = {
        id: this.selectedItem.accountId,
        name: this.selectedItem.name,
        email: this.selectedItem.email,
        password: this.selectedItem.newPassword,
        changePasswordAtNextLogon: !!this.selectedItem.changePasswordAtNextLogon,
        saveEmployee: false,

        // eslint-disable-next-line no-unused-vars
        capabilities: this.checkedCapabilities.map(({ __typename, ...rest }) => rest)
      }

      // if editing employee is active, add employee details
      if (this.isEditingEmployee) {
        accountDTO = {
          ...accountDTO,
          saveEmployee: true,
          employeeId: this.selectedItem.employeeId,
          employmentStartDate: this.employmentStartDate.simple ? this.formatDate(this.employmentStartDate.simple) : null,
          employmentEndDate: this.employmentEndDate.simple ? this.formatDate(this.employmentEndDate.simple) : null,
          keyCode: this.selectedItem.keyCode,
          qualification: this.selectedItem.qualification,
          type: this.selectedItem.type,
          taxCode: this.selectedItem.taxCode,
          phoneNumber: this.selectedItem.phoneNumber,
          family: this.selectedItem.family
        }
      }

      await AccountService.save({
          accountDTO: accountDTO
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
          this.$emit('closeDialog')
        })
    },

    updateCheckedCapabilities(checked, capability) {
      if (checked) {
        this.checkedCapabilities.push(capability)
      } else {
        const index = this.checkedCapabilities.findIndex(item => item.name === capability.name)
        if (index !== -1) {
          this.checkedCapabilities.splice(index, 1)
        }
      }
    },

    disableEmployeeConfirmation() {
      if (confirm(translations.areYouSure)) {
        this.disableEmployee()
      }
    },

    async disableEmployee() {
      this.loading = true
      await EmployeeService.disable({
          employeeId: this.selectedItem.employeeId
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.resetEmployee()
          this.$emit('updated')
        })
        .finally(() => {
          this.loading = false
        })
    },

    resetEmployee() {
      this.selectedItem.employeeId = null
      this.getEmptyDates()
      this.selectedItem.keyCode = null
      this.selectedItem.qualification = null
      this.selectedItem.type = null
      this.selectedItem.taxCode = null
      this.selectedItem.phoneNumber = null

      this.isEditingEmployee = false
      this.hasError = {
        email: false,
        keyCode: false
      }
    }
  },

  watch: {
    show() {
      this.dialog = this.show
    },

    update() {
      this.isUpdate = this.update
    },

    item() {
      this.setItem()
    }
  }
}
</script>
