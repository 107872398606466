<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="Category">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1>
        <v-btn @click="openDialog()" color="main" class="white--text" small depressed :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- keyCode -->
        <td>
          {{ item.keyCode }}
        </td>

        <!-- label -->
        <td>
          {{ item.label }}
        </td>

        <!-- active -->
        <td>
          <v-chip small :color="item.active ? 'light-blue' : 'red'" class="white--text">
            {{ item.active ? translations.yes : translations.no }}
          </v-chip>
        </td>

        <!-- actions -->
        <td width="100">
          <div class="d-flex justify-space-around">
            <!-- loading -->
            <v-progress-circular v-if="item.loading" :size="15" color="main" indeterminate />

            <!-- edit -->
            <v-icon v-if="!item.loading" @click="openDialog(item)" small class="cursor-pointer" :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }">
              mdi-square-edit-outline
            </v-icon>

            <!-- delete -->
            <v-icon v-if="!item.loading" @click="deleteConfirmation(item)" small color="error lighten-1" class="cursor-pointer" :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }">
              mdi-delete-outline
            </v-icon>
          </div>
        </td>
      </template>
    </smart-table>

    <!-- dialog -->
    <category-save-edit-dialog :item="selectedItem" :show="showDialog" :update="isUpdate"
                               @closeDialog="closeDialog()"
                               @updated="$refs.smartTable.getTableRows()" />
  </div>
</template>

<script>
import { translations } from '@/utils/common'

import ProjectService from '@/service/project/ProjectService'

import SmartTable from '@/components/SmartTable'
import CategorySaveEditDialog from '@/views/Category/CategorySaveEditDialog'
import { mapState } from "vuex";

export default {
  name: 'Category',

  components: {
    SmartTable,
    CategorySaveEditDialog
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      isUpdate: false,
      selectedItem: {}
    }
  },

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'keyCode',
        label: translations.keyCode,
        sort: 'keyCode'
      })

      headers.push({
        type: 'TEXT',
        name: 'label',
        label: translations.name,
        sort: 'label'
      })

      headers.push({
        type: 'SELECT',
        name: 'active',
        label: translations.active,
        selectItems: [
          { text: translations.yes, value: true },
          { text: translations.no, value: false }
        ],
        sort: 'active'
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    searchFunction(params) {
      params.fetchNonCategory = false
      params.fetchCategory = true

      // if only one item remove array and set value
      if (params?.active && params?.active.length === 1) {
        params.active = params.active[0]
      }

      // if the array has more than one value or no value remove active from params
      else if (params?.active && (params?.active.length > 1 || params?.active.length === 0)) {
        delete params.active
      }

      return ProjectService.searchProjectList(params)
    },

    openDialog(item) {
      this.showDialog = true
      this.isUpdate = !!item
      this.selectedItem = item ? item : {}
    },

    closeDialog() {
      this.showDialog = false
      this.selectedItem = {}
      this.isUpdate = false
    },

    deleteConfirmation(item) {
      if (confirm(translations.areYouSure)) {
        this.deleteItem(item)
      }
    },

    async deleteItem(item) {
      item.loading = true
      await ProjectService.delete({
          projectId: item.id
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.$refs.smartTable.getTableRows()
        })
        .catch(() => {
          this.$root.vtoast.showError({ message: translations.deletedFailed })
        })
        .finally(() => {
          item.loading = false
        })
    }
  },

  computed: {
    ...mapState({
      canReadOnly: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Read-only')
    })
  }
}
</script>
